import { MODULES, SCOPES } from './scopes.config'

export const handleViewPermission = (key: string) => {
  return [`view_${key}`, `view_all_${key}`, `view_team_${key}`]
}
export interface RouterMenuProps {
  id: number
  path?: string
  parent_id: null | number
  icon?: string
  label: string
  key: string
  slug?: string
  permission_slugs: string | string[]
  slugOptions?: string[]
  breadcrumb?: string[]
  isDetails?: boolean
  isExpanded?: boolean
  isSidebarMenu?: boolean
  hasChild?: boolean
  hasDivider?: boolean
  pathOverride?: string
  publicPermission?: boolean
}

// dashboard

const LOGIN: RouterMenuProps = {
  id: 0,
  path: '/login',
  parent_id: null,
  permission_slugs: [],
  label: 'Login',
  key: 'login',
}

const FORGET_PASSWORD: RouterMenuProps = {
  id: 0,
  path: '/forget-password',
  parent_id: null,
  permission_slugs: [],
  label: 'Forget Password',
  key: 'forget_password',
}
const RESET_PASSWORD: RouterMenuProps = {
  id: 0,
  path: '/reset-password/:token',
  parent_id: null,
  permission_slugs: [],
  label: 'Reset Password',
  key: 'reset_password',
}
const CHANGE_PASSWORD: RouterMenuProps = {
  id: 0,
  path: '/change-password',
  parent_id: null,
  permission_slugs: [],
  label: 'Change Password',
  key: 'change_password',
}
// dashboard

const DASHBOARD: RouterMenuProps = {
  id: 1,
  path: '/dashboard',
  parent_id: null,
  icon: 'dashboard-icon',
  label: 'Dashboard',
  key: 'dashboard',
  permission_slugs: [...handleViewPermission(MODULES.dashboard)],
  // breadcrumb: ["DASHBOARD"],
  isSidebarMenu: true,
}

//Accounts

const ACCOUNTS: RouterMenuProps = {
  id: 3,
  path: '/account',
  parent_id: null,
  icon: 'building-icon',
  label: 'Accounts',
  key: 'customercompany',
  permission_slugs: [...handleViewPermission(MODULES.account)],
  slugOptions: [
    'ACCOUNT_DETAILS_BASIC_INFO',
    'ACCOUNT_DETAILS_CONTACTS',
    'ACCOUNT_DETAILS',
    'ACCOUNT_DETAILS_AGENTS',
    'ACCOUNT_DETAILS_ADDRESSES',
    'ACCOUNT_POSSIBLE_DUPLICATES',
    'ACCOUNT_DETAILS_ORDERS',
    'ACCOUNT_DETAILS_EVENT_LOGS',
    'ACCOUNT_FILES_AND_ATTACHMENTS',
    'ACCOUNT_TASKS',
    'ACCOUNT_DETAILS_NOTES',
  ],
  isSidebarMenu: true,
  hasChild: false,
}

const ACCOUNT_DETAILS: RouterMenuProps = {
  id: 31,
  path: '/account/:id/',
  parent_id: 3,
  isDetails: true,
  icon: 'building-icon',
  label: 'Account Details',
  key: 'account-details',
  permission_slugs: [...handleViewPermission(MODULES.account)],
  breadcrumb: ['ACCOUNT_DETAILS'],
}
const ACCOUNT_DETAILS_EVENT_LOGS: RouterMenuProps = {
  id: 3001,
  path: 'event-logs',
  parent_id: 31,
  icon: 'user',
  label: 'Event Logs',
  key: 'lead-event-logs',
  permission_slugs: [...handleViewPermission(MODULES.account_eventlog)],

  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_EVENT_LOGS'],
}
const ACCOUNT_DETAILS_NOTES: RouterMenuProps = {
  id: 3089,
  path: 'notes',
  parent_id: 3,
  icon: 'user',
  label: 'Notes',
  key: 'account-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.account_note)],
  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_NOTES'],
}
const ACCOUNT_FILES_AND_ATTACHMENTS: RouterMenuProps = {
  id: 3002,
  path: 'attachments',
  parent_id: 31,
  icon: 'user',
  label: 'Files',
  key: 'lead-attachments',
  permission_slugs: [...handleViewPermission(MODULES.account_attachment)],
  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_FILES_AND_ATTACHMENTS'],
}
const ACCOUNT_TASKS: RouterMenuProps = {
  id: 3003,
  path: 'tasks',
  parent_id: 31,
  icon: 'user',
  label: 'Tasks',
  key: 'task-attachments',
  permission_slugs: [...handleViewPermission(MODULES.account_task)],
  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_TASKS'],
}
const ACCOUNT_DETAILS_CONTACTS: RouterMenuProps = {
  id: 314,
  path: 'contacts',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Contacts',
  key: 'account-details-contacts',
  permission_slugs: [...handleViewPermission(MODULES.account_contact)],
  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_CONTACTS'],
}
const ACCOUNT_DETAILS_BASIC_INFO: RouterMenuProps = {
  id: 311,
  path: 'summary',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Summary',
  key: 'account-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.account)],
  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_BASIC_INFO'],
}
const ACCOUNT_DETAILS_AGENTS: RouterMenuProps = {
  id: 312,
  path: 'agents',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Agents',
  key: 'account-details-agents',
  permission_slugs: [...handleViewPermission(MODULES.account_agent)],

  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_AGENTS'],
}
const ACCOUNT_DETAILS_ORDERS: RouterMenuProps = {
  id: 316,
  path: 'orders',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Orders',
  key: 'account-details-orders',
  permission_slugs: [...handleViewPermission(MODULES.account_order)],

  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_ORDERS'],
}
const ACCOUNT_DETAILS_ADDRESSES: RouterMenuProps = {
  id: 313,
  path: 'address',
  parent_id: 31,
  icon: 'building-icon',
  label: 'Addresses',
  key: 'account-details-address',
  permission_slugs: [
    SCOPES.view_customercompany,
    SCOPES.change_customercompany,
  ],
  breadcrumb: ['ACCOUNT_DETAILS', 'ACCOUNT_DETAILS_ADDRESSES'],
}

//Contacts

const CONTACTS: RouterMenuProps = {
  id: 4,
  path: '/contacts',
  parent_id: null,
  icon: 'user',
  label: 'Contacts',
  key: 'customer',
  permission_slugs: [],
  // isSidebarMenu: true,
  slugOptions: [
    'CONTACT_DETAILS_SUMMARY',
    'CONTACT_DETAILS_ACCOUNTS',
    'CONTACT_MERGE',
    'CONTACT_DETAILS_POSSIBLE_DUPLICATES',
    'CONTACT_DETAILS_EVENT_LOGS',
    'CONTACT_FILES_AND_ATTACHMENTS',
    'CONTACT_TASKS',
    'CONTACT_DETAILS_NOTES',
  ],
  hasChild: false,
}

const CONTACT_DETAILS: RouterMenuProps = {
  id: 41,
  path: '/contacts/:id/',
  parent_id: 4,
  isDetails: true,
  icon: 'user',
  label: 'Contact Details',
  key: 'contact-details',
  permission_slugs: [...handleViewPermission(MODULES.contact)],
  breadcrumb: ['CONTACT_DETAILS'],
}
const CONTACT_DETAILS_EVENT_LOGS: RouterMenuProps = {
  id: 5001,
  path: 'event-logs',
  parent_id: 4,
  icon: 'user',
  label: 'Event Logs',
  key: 'lead-event-logs',
  permission_slugs: [...handleViewPermission(MODULES.contact_eventlog)],
  breadcrumb: ['CONTACT_DETAILS', 'CONTACT_DETAILS_EVENT_LOGS'],
}
const CONTACT_DETAILS_NOTES: RouterMenuProps = {
  id: 215,
  path: 'notes',
  parent_id: 21,
  icon: 'user',
  label: 'Notes',
  key: 'contact-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.contact_note)],
  breadcrumb: ['CONTACT_DETAILS', 'CONTACT_DETAILS_NOTES'],
}

const CONTACT_FILES_AND_ATTACHMENTS: RouterMenuProps = {
  id: 4002,
  path: 'attachments',
  parent_id: 41,
  icon: 'user',
  label: 'Files',
  key: 'lead-attachments',
  permission_slugs: [...handleViewPermission(MODULES.contact_attachment)],
  breadcrumb: ['CONTACT_DETAILS', 'CONTACT_FILES_AND_ATTACHMENTS'],
}
const CONTACT_TASKS: RouterMenuProps = {
  id: 4003,
  path: 'tasks',
  parent_id: 41,
  icon: 'user',
  label: 'Tasks',
  key: 'task-attachments',
  permission_slugs: [...handleViewPermission(MODULES.contact_task)],
  breadcrumb: ['CONTACT_DETAILS', 'CONTACT_TASKS'],
}

const CONTACT_DETAILS_SUMMARY: RouterMenuProps = {
  id: 411,
  path: 'summary',
  parent_id: 41,
  icon: 'user',
  label: 'Summary',
  key: 'contact-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.contact)],
  breadcrumb: ['CONTACT_DETAILS', 'CONTACT_DETAILS_SUMMARY'],
}
const CONTACT_DETAILS_ACCOUNTS: RouterMenuProps = {
  id: 412,
  path: 'accounts',
  parent_id: 41,
  icon: 'user',
  label: 'Accounts',
  key: 'contact-details-accounts',
  permission_slugs: [...handleViewPermission(MODULES.contact_account)],
  breadcrumb: ['CONTACT_DETAILS', 'CONTACT_DETAILS_ACCOUNTS'],
}

//My Tasks

const MY_TASKS: RouterMenuProps = {
  id: 5,
  path: '/my-tasks',
  parent_id: null,
  icon: 'mytasks-icon',
  label: 'My Tasks',
  key: 'my-tasks',
  permission_slugs: [],
  publicPermission: true,
  isSidebarMenu: true,
  hasChild: false,
}
const MY_TASKS_VIEW: RouterMenuProps = {
  id: 5,
  path: '/my-tasks/:id',
  parent_id: null,
  icon: 'mytasks-icon',
  label: 'My Tasks',
  key: 'my-tasks',
  permission_slugs: [],
  publicPermission: true,
  isSidebarMenu: false,
  hasChild: false,
}
//My Approvals

const MY_APPROVALS: RouterMenuProps = {
  id: 6,
  path: '/my-approvals',
  parent_id: null,
  icon: 'myaprrovals-icon',
  label: 'My Approvals',
  key: 'my-approvals',
  permission_slugs: [],
  publicPermission: true,

  isSidebarMenu: true,
  hasChild: false,
}
const MY_APPROVAL_DETAILS: RouterMenuProps = {
  id: 6,
  path: '/my-approvals/:id',
  parent_id: null,
  icon: 'myaprrovals-icon',
  label: 'My Approvals',
  key: 'my-approvals',
  permission_slugs: [],
  publicPermission: true,

  isSidebarMenu: false,
  hasChild: false,
}
// Invoices

//My Payment Approvals

const MY_PAYMENT_APPROVALS: RouterMenuProps = {
  id: 7,
  path: '/my-payment-approvals',
  parent_id: null,
  icon: 'paymentapproval-icon',
  label: 'My Payment Approvals',
  key: 'my-payment-approvals',
  permission_slugs: [...handleViewPermission(MODULES.task_approval_request)],
  publicPermission: true,
  isSidebarMenu: true,
  hasChild: false,
  hasDivider: true,
}
const MY_PAYMENT_APPROVAL_DETAILS: RouterMenuProps = {
  id: 7,
  path: '/my-payment-approvals/:id',
  parent_id: null,
  icon: 'paymentapproval-icon',
  label: 'My Payment Approvals',
  key: 'my-payment-approvals',
  permission_slugs: [],
  publicPermission: true,
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: true,
}
//REPORTS

const REPORTS: RouterMenuProps = {
  id: 10,
  path: '/reports',
  parent_id: null,
  icon: 'paymentapproval-icon',
  label: 'Reports',
  key: 'report',
  permission_slugs: [...handleViewPermission(MODULES.report)],
  isSidebarMenu: true,
  hasChild: false,
}
const REPORT_CRE_PERFORMANCE_SUMMARY: RouterMenuProps = {
  id: 101,
  path: '/reports/cre-performace-summary',
  parent_id: 10,
  icon: 'paymentapproval-icon',
  label: 'CRE Performance Reports',
  key: 'cre-perf-reports',
  permission_slugs: [...handleViewPermission(MODULES.report)],
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: false,
}
const REPORT_TEAM_PERFORMANCE_SUMMARY: RouterMenuProps = {
  id: 102,
  path: '/reports/team-performace-summary',
  parent_id: 10,
  icon: 'paymentapproval-icon',
  label: 'Team Performance Reports',
  key: 'team-perf-reports',
  permission_slugs: [...handleViewPermission(MODULES.report)],
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: false,
}
const REPORT_CRE_SERVICE_STATUS: RouterMenuProps = {
  id: 101,
  path: '/reports/cre-service-status',
  parent_id: 10,
  icon: 'paymentapproval-icon',
  label: 'CRE Service Status Reports',
  key: 'cre-service-status',
  permission_slugs: [...handleViewPermission(MODULES.report)],
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: false,
}

//Service Orders

const SERVICE_AND_ORDERS_BY_ORDERS: RouterMenuProps = {
  id: 81,
  path: '/orders',
  isSidebarMenu: true,
  parent_id: null,
  label: 'Orders',
  icon: 'cart-icon',
  key: 'order',
  permission_slugs: [...handleViewPermission(MODULES.order)],
  slugOptions: [
    'SERVICE_AND_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_SERVICES',
    'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS',
    'SERVICE_AND_ORDERS_BY_ORDERS_NOTES',
    'SERVICE_AND_ORDERS_BY_ORDERS_FILES',
    'SERVICE_AND_ORDERS_BY_ORDERS_TASKS',
    'SERVICE_AND_ORDERS_BY_ORDERS_LOGS',
    'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_RECEIPT',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_DETAILS: RouterMenuProps = {
  id: 811,
  path: '/orders/:id/',
  parent_id: 81,
  isDetails: true,
  icon: 'cart-icon',
  label: 'Order Details',
  key: 'order-details',
  permission_slugs: [...handleViewPermission(MODULES.order)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_NOTES: RouterMenuProps = {
  id: 8004,
  path: 'notes',
  parent_id: 81,
  icon: 'user',
  label: 'Notes',
  key: 'order-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.order_note)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_NOTES',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY: RouterMenuProps = {
  id: 8111,
  path: 'summary',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Summary',
  key: 'order-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.order)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_SERVICES: RouterMenuProps = {
  id: 8112,
  path: 'services',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Services',
  key: 'order-details-services',
  permission_slugs: [...handleViewPermission(MODULES.orderService)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_SERVICES',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS: RouterMenuProps = {
  id: 8113,
  path: 'payments',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Payments', //label: 'Allocate Payments',
  key: 'order-details-payments',
  permission_slugs: [...handleViewPermission(MODULES.order_payment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS',
  ],
}

const SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_RECEIPT: RouterMenuProps = {
  id: 8154,
  path: 'order/payment/:id/receipt',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Payments', //label: 'Allocate Payments',
  key: 'order-details-payments',
  permission_slugs: [...handleViewPermission(MODULES.order_receipt)],
}

const SERVICE_AND_ORDERS_BY_ORDERS_LOGS: RouterMenuProps = {
  id: 8116,
  path: 'event-logs',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Event Logs',
  key: 'order-details-logs',
  permission_slugs: [...handleViewPermission(MODULES.order_eventlog)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_LOGS',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_FILES: RouterMenuProps = {
  id: 8114,
  path: 'files',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Files',
  key: 'order-details-files',
  permission_slugs: [...handleViewPermission(MODULES.order_attachment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_FILES',
  ],
}
const SERVICE_AND_ORDERS_BY_ORDERS_TASKS: RouterMenuProps = {
  id: 8114,
  path: 'tasks',
  parent_id: 811,
  icon: 'cart-icon',
  label: 'Tasks',
  key: 'order-details-tasks',
  permission_slugs: [...handleViewPermission(MODULES.order_task)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_ORDERS',
    'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    'SERVICE_AND_ORDERS_BY_ORDERS_TASKS',
  ],
}

const SERVICE_AND_ORDERS_BY_SERVICE: RouterMenuProps = {
  id: 81,
  path: '/services',
  isSidebarMenu: true,
  parent_id: null,
  icon: 'services-icon',
  label: 'Services',
  key: 'orderitem',
  permission_slugs: [...handleViewPermission(MODULES.services)],

  slugOptions: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY',
    'SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS',
    'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS',
    'SERVICE_AND_ORDERS_BY_SERVICE_FILES',
    'SERVICE_AND_ORDERS_BY_SERVICE_NOTES',
    'SERVICE_AND_ORDERS_BY_SERVICE_TASKS',
    'SERVICE_AND_ORDERS_BY_SERVICE_LOGS',
    'SHARE_DELIVERABLES',
    'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL',
    'SERVICE_AND_ORDERS_BY_SERVICE_GOVT_FEES',
    'SERVICE_AND_ORDERS_BY_DATAFORMS',
  ],
}
const EXPORTS: RouterMenuProps = {
  id: 82,
  path: '/exports',
  parent_id: null,
  icon: 'upload',
  label: 'Exports',
  key: 'export',
  permission_slugs: [...handleViewPermission(MODULES.export)],
  isSidebarMenu: true,
  slugOptions: [''],
}
const SERVICE_AND_ORDERS_BY_SERVICE_DETAILS: RouterMenuProps = {
  id: 811,
  path: '/services/:id/',
  parent_id: 81,
  isDetails: true,
  icon: 'services-icon',
  label: 'Service Details',
  key: 'service-details',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
  ],
}
const SERVICE_AND_ORDERS_BY_DATAFORMS: RouterMenuProps = {
  id: 872,
  path: 'data-collection-forms',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Data Collection',
  key: 'forms-builder',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  isSidebarMenu: false,
  hasChild: false,
  hasDivider: false,
}

const SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY: RouterMenuProps = {
  id: 8111,
  path: 'summary',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Summary',
  key: 'service-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY',
  ],
}

const SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS: RouterMenuProps = {
  id: 8112,
  path: 'activity-progress',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Activity Progress',
  key: 'service-details-activity-progress',
  permission_slugs: [...handleViewPermission(MODULES.services_activity)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS',
  ],
}

const SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS: RouterMenuProps = {
  id: 8113,
  path: 'payments',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Payment Allocations',
  key: 'service-details-payments',
  permission_slugs: [...handleViewPermission(MODULES.services_payment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS',
  ],
}

const SERVICE_AND_ORDERS_BY_SERVICE_FILES: RouterMenuProps = {
  id: 8114,
  path: 'files',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Files',
  key: 'service-details-files',
  permission_slugs: [...handleViewPermission(MODULES.services_attachment)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_FILES',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_TASKS: RouterMenuProps = {
  id: 8116,
  path: 'tasks',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Tasks',
  key: 'service-details-tasks',
  permission_slugs: [...handleViewPermission(MODULES.services_task)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_TASKS',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_LOGS: RouterMenuProps = {
  id: 8117,
  path: 'event-logs',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Event Logs',
  key: 'service-details-logs',
  permission_slugs: [...handleViewPermission(MODULES.services_eventlog)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_LOGS',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_NOTES: RouterMenuProps = {
  id: 8115,
  path: 'notes',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Notes',
  key: 'service-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.services_note)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_NOTES',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL: RouterMenuProps = {
  id: 8116,
  path: 'payment-request',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Payment Requests',
  key: 'service-details-payment-approval',
  permission_slugs: [],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL',
  ],
}
const SERVICE_AND_ORDERS_BY_SERVICE_ADDITIONAL_STATUS_LOG: RouterMenuProps = {
  id: 8117,
  path: 'additional-status-log',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Additional Status Log',
  key: 'service-details-additional-status-log',
  permission_slugs: [],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_ADDITIONAL_STATUS_LOG',
  ],
}
const PAYOUTS: RouterMenuProps = {
  id: 20,
  path: '/payouts',
  parent_id: null,
  icon: 'payment-icon',
  label: 'Payouts',
  key: 'paymentout',
  permission_slugs: [...handleViewPermission(MODULES.paymentout)],
  // breadcrumb: ["MY_APPROVALS"],
  isSidebarMenu: true,
  hasChild: false,
  hasDivider: false,
}

const SERVICE_AND_ORDERS_BY_SERVICE_GOVT_FEES: RouterMenuProps = {
  id: 8117,
  path: 'payout',
  parent_id: 811,
  icon: 'services-icon',
  label: 'Payment Out',
  key: 'service-details-govt-fees',
  permission_slugs: [],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SERVICE_AND_ORDERS_BY_SERVICE_GOVT_FEES',
  ],
}

//System Settings

const SYSTEM_SETTINGS: RouterMenuProps = {
  id: 9,

  parent_id: null,
  icon: 'settings',
  label: 'Settings',
  key: 'settings',
  permission_slugs: [SCOPES.employee_dashboard],
  breadcrumb: ['SYSTEM_SETTINGS'],
  isSidebarMenu: true,
  hasChild: true,
}

// System Settings - Organization -Employees

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES: RouterMenuProps = {
  id: 911,
  parent_id: 9,
  label: 'Employees',
  isSidebarMenu: true,
  path: '/employees',
  key: 'employee',
  hasChild: false,
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS: RouterMenuProps = {
  id: 9111,
  path: '/employees/:id/',
  parent_id: 911,
  isDetails: true,
  hasChild: true,
  label: 'Employee Details',
  key: 'employee-details',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES_DETAILS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO: RouterMenuProps = {
  id: 91111,
  path: 'basic-info',
  parent_id: 9111,
  label: 'Summary',
  key: 'employee-details-basic-info',
  permission_slugs: [...handleViewPermission(MODULES.employee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO: RouterMenuProps = {
  id: 91112,
  path: 'team-info',
  parent_id: 9111,
  label: 'Team Info',
  key: 'employee-details-team-info',
  permission_slugs: [...handleViewPermission(MODULES.employeeTeam)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_VENDOR: RouterMenuProps = {
  id: 91113,
  path: 'vendors',
  parent_id: 9111,
  label: 'Vendors',
  key: 'employee-details-vendor',
  permission_slugs: [...handleViewPermission(MODULES.employeeFranchisee)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_VENDOR',
  ],
}

//System Settings - Organization -Teams

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS: RouterMenuProps = {
  id: 912,

  parent_id: 9,
  label: 'Teams',
  isSidebarMenu: true,
  path: '/teams',
  key: 'employeeteam',
  hasChild: false,
  permission_slugs: [...handleViewPermission(MODULES.employeeteam)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS: RouterMenuProps = {
  id: 9121,
  path: '/teams/:id/',
  parent_id: 912,
  isDetails: true,
  hasChild: true,
  label: 'Team Details',
  key: 'team-details',
  permission_slugs: [...handleViewPermission(MODULES.employeeteam)],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO: RouterMenuProps = {
  id: 91211,
  path: 'info',
  parent_id: 9121,
  label: 'Summary',
  key: 'team-details-info',
  permission_slugs: [...handleViewPermission(MODULES.employeeteam)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER: RouterMenuProps = {
  id: 91212,
  path: 'members',
  parent_id: 9121,
  label: 'Members',
  key: 'team-details-members',
  permission_slugs: [...handleViewPermission(MODULES.employeeteam_member)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE: RouterMenuProps = {
  id: 91213,
  path: 'services',
  parent_id: 9121,
  label: 'Services',
  key: 'team-details-services',
  permission_slugs: [...handleViewPermission(MODULES.employeeteam_service)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE',
  ],
}

//System Settings - Organization - Roles

const SYSTEM_SETTINGS_ORGANIZATION_ROLES: RouterMenuProps = {
  id: 913,

  parent_id: 9,
  label: 'Roles',
  isSidebarMenu: true,
  path: '/roles',
  key: 'group',
  permission_slugs: [...handleViewPermission(MODULES.group)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS',
  ],
}
//System Settings - Organization - Roles

const SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS: RouterMenuProps = {
  id: 914,

  parent_id: 9,
  label: 'Stakeholder',
  isSidebarMenu: true,
  path: '/stakeholders',
  key: 'stakeholder',
  permission_slugs: [...handleViewPermission(MODULES.stakeholder)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS',
  ],
}
const SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS: RouterMenuProps = {
  id: 9141,
  path: '/stakeholders/:id/',
  parent_id: 914,
  isDetails: true,
  hasChild: true,
  label: 'Stakeholder Details',
  key: 'stakeholder-details',
  permission_slugs: [...handleViewPermission(MODULES.stakeholder)],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY: RouterMenuProps =
  {
    id: 91411,
    path: 'summary',
    parent_id: 9141,
    label: 'Summary',
    key: 'stakeholder-details-summary',
    permission_slugs: [...handleViewPermission(MODULES.stakeholder)],
    breadcrumb: [
      'SYSTEM_SETTINGS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY',
    ],
  }

const SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS: RouterMenuProps =
  {
    id: 91412,
    path: 'teams',
    parent_id: 9131,
    label: 'Teams',
    key: 'stakeholder-details-teams',
    permission_slugs: [...handleViewPermission(MODULES.stakeholderTeam)],
    breadcrumb: [
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS',
      'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS',
    ],
  }

//System Settings - vendor services

const SYSTEM_SETTINGS_VENDOR_SERVICE: RouterMenuProps = {
  id: 91413,
  parent_id: 9,
  label: 'Service',
  isSidebarMenu: true,
  path: '/vendor-service',
  key: 'service',
  permission_slugs: [...handleViewPermission(MODULES.vendor_service)],
  slugOptions: ['SYSTEM_SETTINGS', 'SYSTEM_SETTINGS_VENDOR_SERVICE'],
}
const SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS: RouterMenuProps = {
  id: 91414,
  parent_id: 91413,
  label: 'Service Details',
  isSidebarMenu: true,
  path: '/vendor-service/:id/',
  key: 'service',
  permission_slugs: [...handleViewPermission(MODULES.vendor_service)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_VENDOR_SERVICE',
    'SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS',
  ],
}
const SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS_SUMMARY: RouterMenuProps = {
  id: 91415,
  parent_id: 91414,
  label: 'Summary',
  isSidebarMenu: true,
  path: 'basic-info',
  key: 'service',
  permission_slugs: [...handleViewPermission(MODULES.vendor_service)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_VENDOR_SERVICE',
    'SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS_SUMMARY',
  ],
}
const SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS_TEAM: RouterMenuProps = {
  id: 91416,
  parent_id: 91414,
  label: 'Team',
  isSidebarMenu: true,
  path: 'team',
  key: 'service',
  permission_slugs: [...handleViewPermission(MODULES.vendor_service)],
  slugOptions: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_VENDOR_SERVICE',
    'SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS',
    'SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS_TEAM',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS: RouterMenuProps = {
  id: 9131,
  path: '/roles/:id/',
  parent_id: 913,
  isDetails: true,
  hasChild: true,
  label: 'Role Details',
  key: 'employee-details',
  permission_slugs: [...handleViewPermission(MODULES.group)],
  breadcrumb: [
    'SYSTEM_SETTINGS',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY: RouterMenuProps = {
  id: 91311,
  path: 'basic-info',
  parent_id: 9131,
  label: 'Summary',
  key: 'role-details-basic-info',
  permission_slugs: [...handleViewPermission(MODULES.group)],
  breadcrumb: [
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
    'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY',
  ],
}

const SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS: RouterMenuProps =
  {
    id: 91312,
    path: 'permissions',
    parent_id: 9131,
    label: 'Permissions',
    key: 'role-details-permissions',
    permission_slugs: [...handleViewPermission(MODULES.group)],
    breadcrumb: [
      'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
      'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
      'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS',
    ],
  }

// System Settings - Data Management

// System Settings - Organization - Services

// const SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES: RouterMenuProps = {
//   id: 921,
//   parent_id: 9,
//   label: 'Services',
//   isSidebarMenu: true,
//   path: '/service-management/service',
//   key: 'services',
//   hasChild: false,
//   permission_slugs: ['admin_only'],
//   slugOptions: [
//     'SYSTEM_SETTINGS',
//     'SYSTEM_SETTINGS_SERVICE_MANAGEMENT',
//     'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
//     'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
//     'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_BASIC_INFO',
//   ],
// }

const ACTIVITIES: RouterMenuProps = {
  id: 12,
  path: '/activities',
  isSidebarMenu: true,
  parent_id: null,
  label: 'Activities',
  icon: 'activities',
  hasDivider: true,
  key: 'orderitemtaskemployee',
  permission_slugs: [...handleViewPermission(MODULES.activity)],
  slugOptions: [
    'ACTIVITIES',
    'ACTIVITY_DETAILS',
    'ACTIVITY_DETAILS_SUMMARY',
    'ACTIVITY_DETAILS_NOTES',
    'ACTIVITY_DETAILS_REQUESTS',
    'ACTIVITY_DETAILS_FILES',
    'ACTIVITY_DETAILS_LOGS',
    'ACTIVITY_DETAILS_TASKS',
  ],
}
const ACTIVITY_DETAILS: RouterMenuProps = {
  id: 101,
  path: '/activities/:id/',
  parent_id: 10,
  isDetails: true,
  icon: 'activities',
  label: 'Activity Details',
  key: 'activities-details',
  permission_slugs: [...handleViewPermission(MODULES.activity)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS'],
}

const SHARE_DELIVERABLES: RouterMenuProps = {
  id: 101,
  path: '/share-deliverables/:id/',
  parent_id: 8001,
  isDetails: true,
  icon: 'activities',
  label: 'Service Deliverables',
  key: 'share-deliverables',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SHARE_DELIVERABLES_SUMMARY',
    'SHARE_DELIVERABLES',
    'SHARE_DELIVERABLES_DETAILS',
  ],
}
const SHARE_DELIVERABLES_SUMMARY: RouterMenuProps = {
  id: 80001,
  path: 'summary',
  parent_id: 8001,
  isDetails: true,
  icon: 'activities',
  label: 'Summary',
  key: 'share-deliverables-summary',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SHARE_DELIVERABLES',
    'SHARE_DELIVERABLES_SUMMARY',
  ],
}
const SHARE_DELIVERABLES_DETAILS: RouterMenuProps = {
  id: 80002,
  path: 'deliverables',
  parent_id: 8001,
  isDetails: true,
  icon: 'activities',
  label: 'Share Deliverables',
  key: 'share-deliverables-details',
  permission_slugs: [...handleViewPermission(MODULES.services)],
  breadcrumb: [
    'SERVICE_AND_ORDERS_BY_SERVICE',
    'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    'SHARE_DELIVERABLES',
    'SHARE_DELIVERABLES_DETAILS',
  ],
}

const ACTIVITY_DETAILS_SUMMARY: RouterMenuProps = {
  id: 1011,
  path: 'summary',
  parent_id: 101,
  icon: 'activities',
  label: 'Summary',
  key: 'activities-details-summary',
  permission_slugs: [...handleViewPermission(MODULES.activity)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_SUMMARY'],
}

const ACTIVITY_DETAILS_NOTES: RouterMenuProps = {
  id: 1012,
  path: 'notes',
  parent_id: 101,
  icon: 'activities',
  label: 'Notes & Checklist',
  key: 'activities-details-notes',
  permission_slugs: [...handleViewPermission(MODULES.activity_note)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_NOTES'],
}

const ACTIVITY_DETAILS_REQUESTS: RouterMenuProps = {
  id: 1013,
  path: 'requests',
  parent_id: 101,
  icon: 'activities',
  label: 'Activity Request',
  key: 'activities-details-requests',
  permission_slugs: [...handleViewPermission(MODULES.activity_request)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_REQUESTS'],
}

const ACTIVITY_DETAILS_FILES: RouterMenuProps = {
  id: 1015,
  path: 'files',
  parent_id: 101,
  icon: 'activities',
  label: 'Files & Attachments',
  key: 'activities-details-files',
  permission_slugs: [...handleViewPermission(MODULES.activity_attachment)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_FILES'],
}
const ACTIVITY_DETAILS_LOGS: RouterMenuProps = {
  id: 1015,
  path: 'event-logs',
  parent_id: 101,
  icon: 'activities',
  label: 'Event Logs',
  key: 'activities-details-logs',
  permission_slugs: [...handleViewPermission(MODULES.activity_eventlog)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_LOGS'],
}
const ACTIVITY_DETAILS_TASKS: RouterMenuProps = {
  id: 1015,
  path: 'tasks',
  parent_id: 101,
  icon: 'deals',
  label: 'Tasks',
  key: 'activities-details-tasks',
  permission_slugs: [...handleViewPermission(MODULES.activity_task)],
  breadcrumb: ['ACTIVITIES', 'ACTIVITY_DETAILS', 'ACTIVITY_DETAILS_TASKS'],
}

const PAYMENTS_RECEIPT: RouterMenuProps = {
  id: 8117,
  path: '/payments/receipt/:id',
  parent_id: null,
  icon: 'cart-icon',
  label: 'reciept',
  key: 'payments-reciept',
  permission_slugs: [...handleViewPermission(MODULES.payment_receipt)],
}
const USER_PROFILE: RouterMenuProps = {
  id: 8118,
  path: '/profile',
  parent_id: null,
  icon: 'cart-icon',
  label: 'reciept',
  key: 'user-profile',
  permission_slugs: [],
}
const NOTIFICATIONS: RouterMenuProps = {
  id: 8119,
  path: '/notifications',
  parent_id: null,
  icon: 'notify-icon',
  label: 'Notifications',
  key: 'user-notifications',
  permission_slugs: [],
  hasDivider: true,
  isSidebarMenu: true,
}

// Note : The arrangements of below "router_config" object will reflect in Sidebar
export const router_config: { [key: string]: RouterMenuProps } = {
  LOGIN,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  USER_PROFILE,
  //
  DASHBOARD,
  //
  MY_TASKS,
  //
  MY_APPROVALS,
  //
  MY_PAYMENT_APPROVALS,
  //
  ACCOUNTS,
  ACCOUNT_DETAILS,
  // //
  ACCOUNT_DETAILS_CONTACTS,
  ACCOUNT_DETAILS_BASIC_INFO,
  ACCOUNT_DETAILS_AGENTS,
  ACCOUNT_DETAILS_ADDRESSES,
  ACCOUNT_DETAILS_ORDERS,
  ACCOUNT_DETAILS_EVENT_LOGS,
  ACCOUNT_FILES_AND_ATTACHMENTS,
  ACCOUNT_TASKS,
  ACCOUNT_DETAILS_NOTES,
  CONTACTS,
  CONTACT_DETAILS,
  CONTACT_DETAILS_EVENT_LOGS,
  CONTACT_FILES_AND_ATTACHMENTS,
  CONTACT_TASKS,
  CONTACT_DETAILS_NOTES,
  CONTACT_DETAILS_SUMMARY,
  CONTACT_DETAILS_ACCOUNTS,
  SERVICE_AND_ORDERS_BY_ORDERS,
  SERVICE_AND_ORDERS_BY_ORDERS_DETAILS,
  SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY,
  SERVICE_AND_ORDERS_BY_ORDERS_SERVICES,
  SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS,
  SERVICE_AND_ORDERS_BY_ORDERS_LOGS,
  SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_RECEIPT,
  SERVICE_AND_ORDERS_BY_ORDERS_FILES,
  SERVICE_AND_ORDERS_BY_ORDERS_TASKS,
  PAYMENTS_RECEIPT,
  SERVICE_AND_ORDERS_BY_ORDERS_NOTES,

  SERVICE_AND_ORDERS_BY_SERVICE,
  SERVICE_AND_ORDERS_BY_SERVICE_DETAILS,
  SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY,
  SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS,
  SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS,
  SERVICE_AND_ORDERS_BY_SERVICE_FILES,
  SERVICE_AND_ORDERS_BY_SERVICE_LOGS,
  SERVICE_AND_ORDERS_BY_SERVICE_TASKS,
  SERVICE_AND_ORDERS_BY_SERVICE_NOTES,
  SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL,
  SERVICE_AND_ORDERS_BY_SERVICE_ADDITIONAL_STATUS_LOG,
  SERVICE_AND_ORDERS_BY_SERVICE_GOVT_FEES,
  SERVICE_AND_ORDERS_BY_DATAFORMS,

  ACTIVITIES,
  ACTIVITY_DETAILS,
  ACTIVITY_DETAILS_SUMMARY,
  ACTIVITY_DETAILS_NOTES,
  ACTIVITY_DETAILS_REQUESTS,
  ACTIVITY_DETAILS_FILES,
  ACTIVITY_DETAILS_LOGS,
  ACTIVITY_DETAILS_TASKS,

  PAYOUTS,

  EXPORTS,

  //
  REPORTS,
  REPORT_CRE_PERFORMANCE_SUMMARY,
  REPORT_TEAM_PERFORMANCE_SUMMARY,
  REPORT_CRE_SERVICE_STATUS,
  NOTIFICATIONS,

  //

  SYSTEM_SETTINGS,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO,
  SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_VENDOR,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER,
  SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE,
  SYSTEM_SETTINGS_ORGANIZATION_ROLES,
  SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS,
  SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY,
  SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS,
  // SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES,
  SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS,
  SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS,
  SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY,
  SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS,
  SYSTEM_SETTINGS_VENDOR_SERVICE,
  SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS,
  SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS_SUMMARY,
  SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS_TEAM,

  SHARE_DELIVERABLES,
  SHARE_DELIVERABLES_DETAILS,
  SHARE_DELIVERABLES_SUMMARY,

  MY_TASKS_VIEW,
  MY_PAYMENT_APPROVAL_DETAILS,
  MY_APPROVAL_DETAILS,
}
